import { PropsWithChildren } from 'react';

import { Button } from '@/components/_molecules/Button';
import { cn } from '@/utils/styles.utils';

interface Props {
  onSearch: () => void;
}

export const SearchWrapper = (props: PropsWithChildren<Props>) => {
  const { children, onSearch } = props;
  return (
    <div
      className={cn(
        'flex h-[360px] flex-col overflow-hidden rounded-lg border-4 border-solid border-grey-700',
        'laptop:h-auto laptop:flex-row laptop:items-center',
      )}
    >
      {children}
      <div className={'flex justify-end p-2'}>
        <Button
          textId="mainSearch.searchButton"
          variant="primary"
          onClick={onSearch}
          className="w-full laptop:w-[160px] desktop:w-[200px]"
        />
      </div>
    </div>
  );
};
