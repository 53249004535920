import React from 'react';

import { ListingBanner } from '@/network/GeneratedApi';
import { cn } from '@/utils/styles.utils';

import { Typography } from '../_atoms/Typography';

interface Props {
  activeIndex: number;
  onClick: (index: number) => void;
  itemOnMouseEnter: (index: number) => void;
  itemOnMouseExit: () => void;
  data: ListingBanner[];
}

export const BannersPagination = (props: Props) => {
  const { data = [], onClick, activeIndex, itemOnMouseEnter, itemOnMouseExit } = props;

  return (
    <div className="hidden h-12 justify-center rounded-b-lg border-t border-grey-300 bg-white laptop:flex">
      {data.map((item, index) => {
        return (
          <button
            onMouseEnter={() => itemOnMouseEnter(index)}
            onMouseLeave={() => itemOnMouseExit()}
            key={item.path}
            onClick={() => onClick(index)}
            className="relative px-3"
          >
            <Typography
              variant="body_s"
              color={index === activeIndex ? 'grey-dark' : 'grey-semi'}
              bold={index === activeIndex}
            >
              {item.banner_label}
            </Typography>
            <div
              className={cn(
                'absolute bottom-0 left-0 right-0 h-1 translate-y-1 bg-orange-500 opacity-0 transition-all',
                {
                  'translate-y-0 opacity-100': index === activeIndex,
                },
              )}
            />
          </button>
        );
      })}
    </div>
  );
};
