import React from 'react';

import * as allIcons from '@/assets/complexIcons';

export type ComplexIcons = keyof typeof allIcons;

interface IconProps {
  size?: number;
  name: ComplexIcons;
  className?: string;
}

export const ComplexIcon = (props: IconProps) => {
  const { size = 32, name, className } = props;
  const IconComponent = allIcons[name];
  if (!IconComponent) {
    return null;
  }

  return <IconComponent className={className || ''} width={size} height={size} viewBox="0 0 32 32" />;
};
