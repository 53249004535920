import { useQuery } from '@tanstack/react-query';

import { request } from '@/network/Api';
import { QueryKeys } from '@/network/query.types';

export async function fetchMainPage() {
  const [mainPage, banners] = await Promise.all([request.mainPageRetrieve(), request.mainPageBannersList()]);
  return {
    ...mainPage,
    banners,
  };
}

export function useMainPageQuery() {
  return useQuery({
    queryKey: [QueryKeys.mainPage],
    queryFn: fetchMainPage,
    refetchOnWindowFocus: true,
  });
}
