import React from 'react';

import { Typography } from '@/components/_atoms/Typography';
import { useProductRate } from '@/logic/product/useProductRate';
import { cn } from '@/utils/styles.utils';

import { RatingStars } from '../RatingStars';

interface Props {
  count?: number;
  value?: string | null;
  hideOnMobile?: boolean;
  showText?: boolean;
  starsCount?: number;
}

export const ProductRating = (props: Props) => {
  const { hideOnMobile, value, count, showText = true } = props;
  const parsedCount = count || 0;
  const { getRating } = useProductRate();
  const { ratingAsNumber, ratingAsStr } = getRating(value);
  return (
    <div className="flex items-center gap-1">
      {showText && (
        <Typography
          variant="body_s"
          bold
          as="span"
          style={{
            lineHeight: 'normal',
          }}
        >
          {ratingAsStr}
        </Typography>
      )}

      <RatingStars rating={ratingAsNumber} />
      {!!parsedCount && (
        <Typography
          variant="body_s"
          color="grey-standard"
          as="span"
          className={cn('laptop:inline-flex', {
            hidden: hideOnMobile,
          })}
          id="productBox.rating.count"
          values={{ count: parsedCount }}
        />
      )}
    </div>
  );
};
