import type { NextPage } from 'next';

import { ComplexIcon, ComplexIcons } from '@/components/_atoms/ComplexIcon';
import { Typography } from '@/components/_atoms/Typography';
import { MessagesKey } from '@/types/translations.types';

const data: { icon: ComplexIcons; textId: MessagesKey }[] = [
  {
    icon: 'box',
    textId: 'homeScreen.topIcons.box',
  },
  {
    icon: 'returnTime',
    textId: 'homeScreen.topIcons.returnTime',
  },
  {
    icon: 'time',
    textId: 'homeScreen.topIcons.time',
  },
  {
    icon: 'shield',
    textId: 'homeScreen.topIcons.shield',
  },
];

export const HomeTopIcons: NextPage = () => {
  return (
    <ul className="-mx-4 mb-2 mt-8 flex flex-col divide-y divide-grey-300 border-t border-grey-300 laptop:mx-0 laptop:mb-0 laptop:flex-row laptop:divide-x laptop:divide-y-0 laptop:border-t-0">
      {data.map((item) => (
        <li
          key={item.icon}
          className="flex h-28 flex-col items-center justify-center gap-2 laptop:h-20 laptop:flex-1 laptop:flex-row laptop:gap-4"
        >
          <ComplexIcon name={item.icon} className="size-8 laptop:size-12" />
          <Typography id={item.textId} bold className="text-center text-body-xl laptop:text-body-l" />
        </li>
      ))}
    </ul>
  );
};
