import { Ref, useId } from 'react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { replaceAll } from '@/utils/string.utils';
import { cn } from '@/utils/styles.utils';

import { Icon } from '../_atoms/Icon';

import styles from './Slider.module.css';

type SwiperProps = Omit<React.ComponentProps<typeof Swiper>, 'modules'>;

interface Props extends SwiperProps {
  pagination?: boolean;
  navigation?: boolean;
  children: React.ReactNode;
  swiperRef?: Ref<SwiperRef>;
  displayPaginationOnlyMobile?: boolean;
}

export const Slider = (props: Props) => {
  const { pagination, displayPaginationOnlyMobile = false, navigation, autoplay, swiperRef, ...restProps } = props;
  const _id = useId();
  const id = replaceAll(_id, ':', '_');
  const prevClass = `swiper-navigation-prev-${id}`;
  const nextClass = `swiper-navigation-next-${id}`;

  const navButtonClassName = cn(
    'absolute top-1/2 z-[10] -mt-3 hidden size-6 cursor-pointer transition-opacity laptop:block',
  );
  const navIconClassName = cn('fill-grey-600 transition-colors group-hover:fill-grey-1000');
  return (
    <div className={'relative'}>
      <Swiper
        ref={swiperRef}
        pagination={pagination}
        className={cn({
          [styles.pagination]: pagination,
          [styles.onlyMobile]: displayPaginationOnlyMobile,
        })}
        navigation={
          navigation
            ? {
                prevEl: `.${prevClass}`,
                nextEl: `.${nextClass}`,
              }
            : undefined
        }
        modules={[pagination ? [Pagination] : [], navigation ? [Navigation] : [], !!autoplay ? [Autoplay] : []].flat()}
        autoplay={autoplay}
        {...restProps}
      />
      {navigation && (
        <>
          <div className={cn(prevClass, navButtonClassName, 'left-0 laptop:-left-8 desktop:-left-12')}>
            <Icon name="chevronLeft" className={navIconClassName} />
          </div>
          <div className={cn(nextClass, navButtonClassName, 'right-0 laptop:-right-8 desktop:-right-12')}>
            <Icon name="chevronRight" className={navIconClassName} />
          </div>
        </>
      )}
    </div>
  );
};

Slider.Slide = SwiperSlide;
