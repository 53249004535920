import React, { useRef, useState } from 'react';
import { SwiperRef } from 'swiper/react';

import { BannersPagination } from '@/components/BannersPagination';
import { Slider } from '@/components/Slider';
import { MainPageBanner } from '@/network/GeneratedApi';

import { Banner } from './components/Banner';

interface Props {
  data: MainPageBanner[];
}

export const BannersSection = (props: Props) => {
  const swiperRef = useRef<SwiperRef>(null);
  const { data } = props;
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const onClick = (index: number) => {
    swiperRef.current?.swiper.slideTo(index);
  };
  const paginationOnMouseEnter = (index: number) => {
    swiperRef.current?.swiper.slideTo(index);
    swiperRef.current?.swiper.autoplay.stop();
  };
  const paginationOnMouseExit = () => {
    swiperRef.current?.swiper.autoplay.start();
  };

  if (!data.length) {
    return null;
  }

  return (
    <div className="mx-[calc(50%-50vw)] w-screen">
      <Slider
        swiperRef={swiperRef}
        onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
        pagination
        displayPaginationOnlyMobile
        slidesPerView={1}
        spaceBetween={0}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
      >
        {data.map((item, index) => (
          <Slider.Slide key={item.path}>
            <Banner key={item.path} index={index} {...item} />
          </Slider.Slide>
        ))}
      </Slider>
      <BannersPagination
        data={data}
        onClick={onClick}
        activeIndex={activeSlideIndex}
        itemOnMouseEnter={paginationOnMouseEnter}
        itemOnMouseExit={paginationOnMouseExit}
      />
    </div>
  );
};
