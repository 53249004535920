import React from 'react';

import { Typography } from '@/components/_atoms/Typography';
import { MainPageReview } from '@/network/GeneratedApi';

export const ReviewItem = (props: MainPageReview) => {
  const { description, user_alias } = props;

  return (
    <div className="flex h-[220px] flex-col rounded-lg bg-grey-100 p-6">
      <div className="flex-1">
        <Typography variant="body_l" className="mb-4">
          {description}
        </Typography>
        <Typography variant="body_m">{user_alias}</Typography>
      </div>
      <Typography variant="body_l" bold color="green" id="reviewItem.recommended" />
    </div>
  );
};
