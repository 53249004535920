import { useState } from 'react';

import { SelectSize } from '@/components/SelectSize/SelectSize';
import { Typography } from '@/components/_atoms/Typography';
import { CheckboxGroup } from '@/components/_molecules/CheckboxGroup';
import { useModals } from '@/components/_molecules/Modals/Modals';
import { useTranslations } from '@/hooks/useTranslations';
import { useCar } from '@/logic/selectCar';
import { SelectedTyreValue } from '@/logic/selectedTyreConfig';
import { useNavigateToTyrePage } from '@/logic/tyresListing/useNavigateToTyrePage';
import sizes from '@/preval/sizes.preval';
import { FILTER_SEASON_ID, FILTER_SIZE_ID, TYRE_DEFAULT_SIZE } from '@/types/TyreSearch.types';

import { SearchWrapper } from '../SearchWrapper';

interface Props {
  query?: Record<string, any>;
  smallSelect?: boolean;
  initialData: SelectedTyreValue | null;
}

export const SearchBySize = (props: Props) => {
  const { openInfoModal } = useModals();
  const { t } = useTranslations();
  const { query = {}, smallSelect, initialData } = props;
  const navigate = useNavigateToTyrePage(false);
  const { removeCar } = useCar();
  const { main_page_seasons } = sizes;

  const [seasons, setSeasons] = useState<string[]>(initialData?.season || []);
  const [size, setSize] = useState<string | null>(initialData?.size || TYRE_DEFAULT_SIZE);
  const [actualSize, setActualSize] = useState<string | null>(TYRE_DEFAULT_SIZE);

  const handleChangeSize = (newSize: string | null) => {
    if (newSize) {
      setSize(newSize);
    }
    setActualSize(newSize);
  };

  const handleClickSearch = () => {
    if (!actualSize) {
      return openInfoModal({
        title: t('mainSearch.size.alertTitle'),
        description: t('mainSearch.size.alertText'),
      });
    }
    removeCar();
    navigate({
      query: {
        [FILTER_SIZE_ID]: size || undefined,
        [FILTER_SEASON_ID]: seasons,
        ...query,
      },
    });
  };
  return (
    <>
      <SearchWrapper onSearch={handleClickSearch}>
        <SelectSize value={size} onChange={handleChangeSize} type="home" small={smallSelect} />
        <div className="flex flex-1 flex-col justify-between px-2 laptop:flex-row">
          <div className="flex flex-col justify-center p-2 laptop:p-0">
            <Typography as="span" variant="body_m" id="mainSearch.seasonLabel" className="mb-3 laptop:mb-1" />
            <CheckboxGroup size="s" direction="row" value={seasons} onChange={setSeasons} options={main_page_seasons} />
          </div>
        </div>
      </SearchWrapper>
    </>
  );
};
