import React from 'react';

import { Slider } from '@/components/Slider';
import { Typography } from '@/components/_atoms/Typography';
import { MainPageTyreModel } from '@/network/GeneratedApi';

import { RecommendedItem } from './componenents/RecommendedItem';

interface Props {
  data: MainPageTyreModel[];
}

export const RecommendedSection = (props: Props) => {
  const { data } = props;
  return (
    <>
      <Typography
        as="h2"
        bold
        id="homeScreen.recommended.header"
        className="mb-6 text-header-l laptop:mb-10 laptop:text-header-xl"
      />
      <Slider
        pagination
        navigation
        breakpoints={{
          1024: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          0: {
            slidesPerView: 1.3,
            spaceBetween: 8,
          },
        }}
      >
        {data.map((item) => (
          <Slider.Slide key={item.path}>
            <RecommendedItem key={item.path} {...item} />
          </Slider.Slide>
        ))}
      </Slider>
    </>
  );
};
