import React from 'react';

import { PostItem } from '@/components/PostItem';
import { Slider } from '@/components/Slider';
import { Typography } from '@/components/_atoms/Typography';
import { MainPagePost } from '@/network/GeneratedApi';

interface Props {
  data: MainPagePost[];
}

export const PostsSection = (props: Props) => {
  const { data } = props;
  return (
    <>
      <Typography
        as="h2"
        bold
        id="homeScreen.posts.header"
        className={'mb-6 text-header-l laptop:mb-10 laptop:text-header-xl'}
      />
      <Slider
        pagination
        navigation
        breakpoints={{
          1024: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          0: {
            slidesPerView: 1.05,
            spaceBetween: 8,
          },
        }}
      >
        {data.map((item) => (
          <Slider.Slide key={item.path}>
            <PostItem key={item.path} {...item} />
          </Slider.Slide>
        ))}
      </Slider>
    </>
  );
};
