import { OrganizationJsonLd } from 'next-seo';

import { PHONE } from '@/constants/contact.constants';
import { getPathWithDomain } from '@/utils/router.utils';

export const HomeStructuredData = () => {
  return (
    <OrganizationJsonLd
      name="NoweOpony.pl"
      url={getPathWithDomain('/') || ''}
      logo={getPathWithDomain('/brand/logoDark.svg')}
      image={getPathWithDomain('/brand/logoDark.svg')}
      sameAs={['https://www.facebook.com/noweoponypl']}
      award={[
        'Ranking Zaufanych Sklepów 2023 - Ceneo',
        'II miejsce w kategorii Motoryzacja w Rankingu Zaufane Sklepy 2023',
      ]}
      address={{
        streetAddress: 'Zagłoby 11/22',
        addressLocality: 'Rzeszów',
        addressRegion: 'PL',
        postalCode: '35-105',
        addressCountry: 'Poland',
      }}
      telephone={PHONE}
    />
  );
};
