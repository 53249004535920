import React from 'react';

import { Slider } from '@/components/Slider';
import { Grid } from '@/components/_atoms/Grid';
import { Typography } from '@/components/_atoms/Typography';
import { MainPageProducer } from '@/network/GeneratedApi';
import { chunkArray } from '@/utils/array.utils';

import { ProducerItem } from './componenents/ProducerItem';

interface Props {
  data: MainPageProducer[];
}

export const ProducersSection = (props: Props) => {
  const { data } = props;
  const producers = chunkArray(data, 3);
  return (
    <>
      <Grid className="mb-8 laptop:mb-14">
        <Grid.Item mobile={12} laptop={6}>
          <Typography
            as="h2"
            bold
            id="homeScreen.producers.header"
            className="mb-4 text-header-l laptop:mb-10 laptop:text-header-xl"
          />
          <Typography variant="body_xl" id="homeScreen.producers.text" color="grey-standard" />
        </Grid.Item>
      </Grid>
      <Slider
        pagination
        breakpoints={{
          1024: {
            slidesPerView: 5,
            spaceBetween: 24,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 12,
          },
          0: {
            slidesPerView: 2,
            spaceBetween: 8,
          },
        }}
      >
        {producers.map((item) => (
          <Slider.Slide key={item.map((v) => v.path).join('')}>
            <ProducerItem data={item} />
          </Slider.Slide>
        ))}
      </Slider>
    </>
  );
};
