import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { MainPageProducer } from '@/network/GeneratedApi';

interface Props {
  data: MainPageProducer[];
}

export const ProducerItem = (props: Props) => {
  const { data } = props;
  return (
    <div>
      {data.map((item) => (
        <Link
          href={item.path}
          key={item.path}
          className="mb-2 flex h-32 items-center justify-center rounded-lg bg-grey-100 px-4 last:mb-0 tablet:mb-3 laptop:mb-6"
        >
          {item.logo && (
            <Image
              quality={100}
              alt={item.name}
              src={item.logo}
              width={150}
              height={100}
              style={{
                objectFit: 'contain',
              }}
            />
          )}
        </Link>
      ))}
    </div>
  );
};
