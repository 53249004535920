import { useState } from 'react';

import { SelectCar } from '@/components/SelectCar/SelectCar';
import { useModal, Modal, ModalContent } from '@/components/_molecules/Modal';
import { useModals } from '@/components/_molecules/Modals';
import { useTranslations } from '@/hooks/useTranslations';
import { SelectCarValue, useCar } from '@/logic/selectCar';
import { isSelectCarFull } from '@/logic/selectCar/selectCar.utils';
import { useNavigateToTyrePage } from '@/logic/tyresListing/useNavigateToTyrePage';
import { CarSizeDetail } from '@/network/GeneratedApi';
import { FILTER_SIZE_ID } from '@/types/TyreSearch.types';

import { SearchWrapper } from '../SearchWrapper';
import { SelectCarSizeModal } from '../SelectCarSizeModal';

interface Props {
  query?: Record<string, any>;
  initialData: SelectCarValue | null;
}

export const SearchByCar = (props: Props) => {
  const { openInfoModal } = useModals();
  const { t } = useTranslations();
  const { initialData } = props;
  const navigate = useNavigateToTyrePage(false);

  const carSizeNewModal = useModal();
  const { selectCar } = useCar();

  const [value, setValue] = useState<SelectCarValue | null>(initialData);
  const isFullSelect = isSelectCarFull(value);
  const handleClickSearch = () => {
    if (!isFullSelect) {
      openInfoModal({
        title: t('mainSearch.car.alertTitle'),
        description: t('mainSearch.car.alertText'),
      });
    }
    carSizeNewModal.open();
  };

  const onSelectCarSize = (sizeItemId: string, data: CarSizeDetail) => {
    if (!isFullSelect) {
      return;
    }
    selectCar({
      ...value,
      sizeItemId,
      size: data.size,
    });
    navigate({
      query: {
        ...data.filters,
        [FILTER_SIZE_ID]: data.size,
      },
    });
  };

  return (
    <>
      <SearchWrapper onSearch={handleClickSearch}>
        <SelectCar onChange={setValue} value={value} type="home" />
      </SearchWrapper>
      {isFullSelect && (
        <>
          <Modal isOpen={carSizeNewModal.isOpen} onOpenChange={carSizeNewModal.setIsOpen}>
            <ModalContent>
              <SelectCarSizeModal onSelectSize={onSelectCarSize} data={value} onClose={carSizeNewModal.close} />
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};
