import React from 'react';

import { ComplexIcon, ComplexIcons } from '@/components/_atoms/ComplexIcon';
import { Grid } from '@/components/_atoms/Grid';
import { Typography } from '@/components/_atoms/Typography';
import { MessagesKey } from '@/types/translations.types';

const data: { id: number; icon: ComplexIcons; headerId: MessagesKey; textId: MessagesKey }[] = [
  {
    id: 1,
    icon: 'box',
    headerId: 'homeScreen.whyUs.1.header',
    textId: 'homeScreen.whyUs.1.text',
  },
  {
    id: 2,
    icon: 'time',
    headerId: 'homeScreen.whyUs.2.header',
    textId: 'homeScreen.whyUs.2.text',
  },
  {
    id: 3,
    icon: 'returnTime',
    headerId: 'homeScreen.whyUs.3.header',
    textId: 'homeScreen.whyUs.3.text',
  },
  {
    id: 4,
    icon: 'shield',
    headerId: 'homeScreen.whyUs.4.header',
    textId: 'homeScreen.whyUs.4.text',
  },
  {
    id: 5,
    icon: 'box',
    headerId: 'homeScreen.whyUs.5.header',
    textId: 'homeScreen.whyUs.5.text',
  },
];

export const WhyUsSection = () => {
  return (
    <Grid>
      <Grid.Item mobile={12} laptop={3}>
        <Typography
          as="h2"
          variant="header_l"
          bold
          id="homeScreen.whyUs.header"
          className="mb-6 text-header-l laptop:mb-10 laptop:text-header-xl"
        />
        <Typography
          variant="body_l"
          id="homeScreen.whyUs.text"
          color="grey-standard"
          className="mb-8 text-body-l laptop:mb-0 laptop:text-body-xl"
        />
      </Grid.Item>
      <Grid.Item mobile={12} laptop={8} laptopStart={5}>
        <ul className="grid gap-6 tablet:grid-cols-2 laptop:grid-cols-3 laptop:gap-10">
          {data.map((item) => (
            <li className="flex gap-4" key={item.id}>
              <ComplexIcon size={32} name={item.icon} className="flex-shrink-0 laptop:mb-0" />
              <div>
                <Typography
                  variant="body_xl"
                  bold
                  id={item.headerId}
                  className="relative mb-10 after:absolute after:-bottom-4 after:left-0 after:h-[3px] after:w-12 after:bg-orange-500 after:content-['']"
                />
                <Typography variant="body_m" color="grey-standard" id={item.textId} />
              </div>
            </li>
          ))}
        </ul>
      </Grid.Item>
    </Grid>
  );
};
