import React from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';

import { Typography } from '@/components/_atoms/Typography';

interface Props {
  createdAt: string;
  readTime?: number;
}

export const PostItemTime = (props: Props) => {
  const { createdAt, readTime = 0 } = props;

  return (
    <div>
      <Typography as="span" variant="body_s" color="grey-standard">
        <FormattedDate value={createdAt} dateStyle="long" />
      </Typography>
      <Typography as="span" variant="body_s" color="grey-light" className="ml-1 mr-1">
        •
      </Typography>
      <Typography as="span" variant="body_s" color="grey-standard">
        <FormattedNumber value={readTime} style="unit" unit="minute" />
      </Typography>
    </div>
  );
};
