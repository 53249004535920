import { useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { DataLayerService } from '@/services/gtm';

import { AnalyticsEventsMapper } from './analytics.mappers';

type Slot = 'listing_top_banner' | 'main_page_top_banner' | 'product_main_banner' | 'product_landing_page_banner';

export const useAnalyticsBanner = (name: string, index: number, slot: Slot) => {
  const seen = useRef<boolean>(false);
  const onClick = () => DataLayerService.selectPromotion(AnalyticsEventsMapper.promotion(name, index, slot));

  const onView = () => {
    if (seen.current) {
      return;
    }
    seen.current = true;
    DataLayerService.viewPromotion(AnalyticsEventsMapper.promotion(name, index, slot));
  };

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      onView();
    }
  }, [inView]);

  return { onClick, ref };
};
