import { FormatNumberOptions, createIntl } from 'react-intl';

export const MAX_PRODUCT_RATING = 5;

export const useProductRate = () => {
  const rateIntl = createIntl({
    locale: 'en-US',
  });
  const getRating = (value: string | number | null | undefined, options?: FormatNumberOptions) => {
    const rating = Number(value) || 0;
    return {
      ratingAsStr: rateIntl.formatNumber(rating, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
        ...options,
      }),
      ratingAsNumber: rating,
    };
  };
  return {
    getRating,
  };
};
