import { getImageProps } from 'next/image';
import Link from 'next/link';
import React from 'react';

import { BREAKPOINT_LAPTOP, BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '@/constants/breakpoints.constants';
import { useAnalyticsBanner } from '@/logic/analytics/useAnalyticsBanner';
import { MainPageBanner } from '@/network/GeneratedApi';

export const Banner = (props: MainPageBanner & { index: number }) => {
  const {
    main_page_desktop_banner: desktopBanner,
    main_page_desktop_banner_content: desktopContent,
    main_page_mobile_banner: mobileBanner,
    main_page_mobile_banner_content: mobileContent,
    main_page_tablet_banner: tabletBanner,
    main_page_tablet_banner_content: tabletContent,
    path,
    name,
    index,
  } = props;
  const { ref, onClick } = useAnalyticsBanner(name, index, 'main_page_top_banner');

  const priority = index === 0;
  const quality = 100;

  // banner - desktop
  const {
    props: { srcSet: _desktopBanner },
  } = getImageProps({
    alt: 'Banner',
    width: 2000,
    height: 580,
    quality,
    src: desktopBanner as string,
    priority,
  });
  // banner - tablet
  const {
    props: { srcSet: _tabletBanner },
  } = getImageProps({
    alt: 'Banner',
    width: 1000,
    height: 500,
    quality,
    src: tabletBanner as string,
    priority,
  });

  // banner - mobile
  const {
    props: { srcSet: _mobileBanner, ...bannerRestProps },
  } = getImageProps({
    alt: 'Banner',
    width: 768,
    height: 480,
    quality,
    src: mobileBanner as string,
    priority,
  });

  // content - desktop
  const {
    props: { srcSet: _desktopContent },
  } = getImageProps({
    alt: 'Content',
    width: 1174,
    height: 580,
    quality,
    src: desktopContent as string,
    priority,
  });
  // content - tablet
  const {
    props: { srcSet: _tabletContent },
  } = getImageProps({
    alt: 'Content',
    width: 640,
    height: 500,
    quality,
    src: tabletContent as string,
    priority,
  });

  // content - mobile
  const {
    props: { srcSet: _mobileContent, ...contentRestProps },
  } = getImageProps({
    alt: 'Content',
    width: 320,
    height: 480,
    quality,
    src: mobileContent as string,
    priority,
  });

  return (
    <div className="relative h-[480px] tablet:h-[500px] laptop:h-[580px]" ref={ref}>
      <picture>
        {_desktopBanner && <source srcSet={_desktopBanner} media={`(min-width: ${BREAKPOINT_LAPTOP}px)`} />}
        {_tabletBanner && <source srcSet={_tabletBanner} media={`(min-width: ${BREAKPOINT_TABLET}px)`} />}
        {_mobileBanner && <source srcSet={_mobileBanner} media={`(min-width: ${BREAKPOINT_MOBILE}px)`} />}
        <img
          {...bannerRestProps}
          className="absolute inset-0 h-0 max-h-full min-h-full w-0 min-w-full max-w-full object-cover object-bottom"
        />
      </picture>
      {/* 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error  path will be not undefined */}
      <Link href={path} onClick={onClick}>
        <picture>
          {_desktopContent && <source srcSet={_desktopContent} media={`(min-width: ${BREAKPOINT_LAPTOP}px)`} />}
          {_tabletContent && <source srcSet={_tabletContent} media={`(min-width: ${BREAKPOINT_TABLET}px)`} />}
          {_mobileContent && <source srcSet={_mobileContent} media={`(min-width: ${BREAKPOINT_MOBILE}px)`} />}
          <img
            {...contentRestProps}
            className="absolute inset-0 h-0 max-h-full min-h-full w-0 min-w-full max-w-full object-contain object-bottom"
          />
        </picture>
      </Link>
    </div>
  );
};
