import { MAX_PRODUCT_RATING } from '@/logic/product/useProductRate';

import styles from './RatingStars.module.css';

interface Props {
  max?: number;
  rating: number;
}

export const RatingStars = (props: Props) => {
  const { max = MAX_PRODUCT_RATING, rating } = props;

  const style = { '--rating': rating, '--max-rating': max } as React.CSSProperties;
  return <div className={styles.stars} style={style} />;
};
